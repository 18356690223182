export const Routes = {
  // GENERIC
  getin: {
    path: "/getin/:id",
    clean: "/getin/",
  },

  AdminUsers: { path: "/admin/users" },
  AdminOrganizations: { path: "/admin/organizations" },
  SignIn: { path: "/signin" },
  ForgotPassword: { path: "/forgotPassword" },
  Register: { path: "/register" },
  Home: { path: "/" },
  CheckProfile: { path: "/checkProfile" },

  NotFound: { path: "/examples/NotFound" },
  ServerError: { path: "/examples/ServerError" },
  Chat: { path: "/Chat" },
  Settings: { path: "/Settings/:id?", clean: "/Settings/" },
  Reports: { path: "/reports/:id" },
  Profile: { path: "/profile" },
  Search: { path: "/search" },
  Employees: { path: "/employees" },

  Complains: { path: "/complains" },
  Incidents: { path: "/incidents" },
  Alerts: { path: "/alerts" },

  SelectOrganization: { path: "/SelectOrganization" },

  Admin: { path: "/Admin/:id?", clean: "/Admin/" },
  Projects: { path: "/Projects", clean: "/Projects/" },
  Dashboard: { path: "/Dashboard", clean: "/Dashboard/" },
  DashboardProjectDetails: { path: "/Dashboard/:id", clean: "/Dashboard/" },


}
