interface RouteDTO {
  path: string
  component: FC
  withSidebar: boolean
  exact?: boolean
}
import { FC } from "react"
import { Routes } from "../routes"

import ProfilePage from "./ProfilePage"

import ReportsPage from "./ReportsPage"
import SettingsPage from "./SettingsPage"

import AdminUsersPage from "./AdminUsersPage"
import AdminOrganizationPage from "./AdminOrganizationPage"
import LoginPage from "./LoginPage"
import RegisterPage from "./RegisterPage"
import ForgotPassword from "../components/Profile/ForgotPassword"
import HomePage from "./HomePage"
import SelectOrganization from "../components/Organizations/SelectOrganization"
import CheckProfilePage from "./CheckProfilePage"
import AdminPage from "./AdminPage/AdminPage"
import ComplainPage from "./ComplainPage"
import ProjectsPage from "./ProjectsPage"
import DashboardPage from "./DashboardPage"

const routesWithComponent: RouteDTO[] = [
  {
    path: Routes.Projects.path,
    component: ProjectsPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.DashboardProjectDetails.path,
    component: DashboardPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Dashboard.path,
    component: DashboardPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Admin.path,
    component: AdminPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Home.path,
    component: HomePage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Complains.path,
    component: ComplainPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.CheckProfile.path,
    component: CheckProfilePage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Profile.path,
    component: ProfilePage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.Reports.path,
    component: ReportsPage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.Settings.path,
    component: SettingsPage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.AdminUsers.path,
    component: AdminUsersPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.AdminOrganizations.path,
    component: AdminOrganizationPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.SignIn.path,
    component: LoginPage,
    withSidebar: false,
    exact: true,
  },

  {
    path: Routes.ForgotPassword.path,
    component: ForgotPassword,
    withSidebar: false,
    exact: true,
  },
  {
    path: Routes.Register.path,
    component: RegisterPage,
    withSidebar: false,
    exact: true,
  },

  {
    path: Routes.SelectOrganization.path,
    component: SelectOrganization,
    withSidebar: false,
    exact: true,
  },
]

export default routesWithComponent
