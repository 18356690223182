import { parsedUser } from "../../common/GetCurrentUser"
import CRUDField from "../core/genericCRUD/CRUDField"
const user = parsedUser()
const ProjectDTO: CRUDField[] = [
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "organizationId",
    title: "organizationId",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "name",
    title: "Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-12 col-12",
    groupNumber: 0,
  },
  {
    name: "responsableId",
    title: "Responsible Engineer",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    datasourceMethod: "POST",
    dataSource: "employee/list",
    dependOf: "organizationId",
    dependOfValue: user?.OrganizationId,

    dataSourceTextField: "fullName",
    dataSourceValueField: "employeeId",
  },
  {
    name: "secondReponsableId",
    title: "Second Responsible Engineer",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    datasourceMethod: "POST",
    dataSource: "employee/list",
    dependOf: "organizationId",
    dependOfValue: user?.OrganizationId,

    dataSourceTextField: "fullName",
    dataSourceValueField: "employeeId",
  },
  {
    name: "notes",
    title: "Notes",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },

  {
    name: "address",
    title: "Address",
    htmlType: "searchMap",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },

  {
    name: "latitude",
    title: "Latitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "longitude",
    title: "Longitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "locationMap",
    title: "Map",
    htmlType: "map",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },
]

export default ProjectDTO
