import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import { Card, Col, Container, Row } from "react-bootstrap"

import GoogleMapReact from "google-map-react"
import PinComponent from "../core/PinComponent"
import Settings from "../../common/Settings"
import API from "../../common/API"
import { useTranslation } from "react-i18next"
import { set } from "lodash"
import { useHistory, useParams } from "react-router"
import { default as ProjectAddEdit } from "../Projects/AddEdit"
import { Routes } from "../../routes"
function DashboardContainer() {
  const GoogleMapRef = React.createRef()
  const [zoom, setZoom] = useState(17)
  const [records, setRecords] = useState([])
  const [center, setCenter] = useState({ lat: 18.4718609, lng: -69.8923187 })
  const user = parsedUser()
  const { id } = useParams<{ id: string }>()
  const [theMap, setTheMap] = useState<any>(null)
  const [theMapsReference, setTheMapsReference] = useState<any>(null)
  const [avoidAutoZoom, setAvoidAutoZoom] = useState(false)
  const { t } = useTranslation("global")
  const [responsable, setResponsable] = useState(null)
  const [responsables, setResponsables] = useState([])
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [projects, setProjects] = useState([])
  const [selectedBuilding, setSelectedbuilding] = useState(null)
  const [buildings, setBuildings] = useState([])
  const router = useHistory()
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }
  const onHandleZoom = obj => {
    console.log(obj)
    setZoom(obj.zoom)
  }
  const handleMapClick = e => {
    setCenter({ lat: e.lat, lng: e.lng })
  }

  const bindProjects = async () => {
    try {
      let query = {
        organizationId: user.OrganizationId,
      }
      const request = await API.postAction("projects/list", query)
      if (request.data.status === "ok") {
        setRecords(request.data.response)
        setProjects(request.data.response)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const bindResponsables = async () => {
    try {
      let query = {
        organizationId: user.OrganizationId,
      }
      const request = await API.postAction(
        "employee/ListWithProjectAssigned",
        query
      )
      if (request.data.status === "ok") {
        setResponsables(request.data.response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const centerMap = () => {
    if (avoidAutoZoom === true) {
      return
    }
    if (theMapsReference) {
      const bounds = new theMapsReference.LatLngBounds()

      records.forEach((marker: any) => {
        if (marker.latitude) {
          bounds.extend(
            new theMapsReference.LatLng(marker.latitude, marker.longitude)
          )
        }
      })
      if (theMap) {
        theMap.fitBounds(bounds)
      }
      //theMap.setZoom(zoom);
    }
  }
  const toggleProject = obj => {
    if (selectedProject) {
      setSelectedProject(null)
    } else {
      setSelectedProject(obj)
    }
  }
  const pinSelected = obj => {
    console.log(obj)
    router.push(Routes.DashboardProjectDetails.clean + obj)
  }

  useEffect(() => {
    if (id) {
      setSelectedProject({ id: id })
    }
  }, [id])

  useEffect(() => {
    bindProjects()
    bindResponsables()
  }, [])

  useEffect(() => {
    centerMap()
  }, [records])
  return (
    <>
      {selectedProject && (
        <ProjectAddEdit
          toggle={() => {
            toggleProject(null)
          }}
          record={selectedProject}
        />
      )}
      <Container fluid>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              {/* <Col>
                <div className="input-group">
                  <label className="input-group-text">
                    {t("Responsible Engineer")}
                  </label>
                  <select className="form-control">
                    <option value={""}>{t("Select one")}</option>
                    {responsables.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Col> */}
              <Col xs={12} md={4}>
                <div className="input-group">
                  <label className="input-group-text">{t("Project")}</label>
                  <select className="form-control">
                    <option value={""}>{t("Select one")}</option>
                    {projects.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="input-group">
                  <label className="input-group-text">{t("Building")}</label>
                  <select className="form-control"></select>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="shadow-lg">
          <Card.Body className="p-0">
            <GoogleMapReact
              ref={GoogleMapRef}
              // onClick={this.handleClick}
              onClick={handleMapClick}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
              style={{
                width: "100%",
                minHeight: "80vh",
                // height: "100vh",
                position: "relative",
              }}
              //id={this.props.Element.nombreCampo}
              center={{ lat: center.lat, lng: center.lng }}
              defaultZoom={zoom}
              onChange={e => onHandleZoom(e)}
            >
              {records.map((marker: any) => (
                <PinComponent
                  key={marker.id}
                  // item={marker}
                  id={marker.id}
                  lat={marker.latitude}
                  lng={marker.longitude}
                  title={marker.name}
                  toggle={pinSelected}
                />
              ))}
            </GoogleMapReact>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default DashboardContainer
