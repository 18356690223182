import CRUDField from "../core/genericCRUD/CRUDField"

const ProjectBuildingDTO: CRUDField[] = [
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "organizationId",
    title: "organizationId",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "name",
    title: "Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "notes",
    title: "Notes",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },

  {
    name: "address",
    title: "Address",
    htmlType: "searchMap",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },

  {
    name: "latitude",
    title: "Latitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "longitude",
    title: "Longitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "locationMap",
    title: "Map",
    htmlType: "map",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },
  {
    name: "projectId",
    title: "ProjectId",
    htmlType: "hidden",
    dbType: "string",
  },
]

export default ProjectBuildingDTO
