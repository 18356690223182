export default [
  {
    name: "name",
    htmlType: "text",
    dbType: "string",
    title: "Name",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "lastName",
    htmlType: "text",
    dbType: "string",
    title: "Last Name",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "alias",
    htmlType: "text",
    dbType: "string",
    title: "Alias",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "tss",
    htmlType: "text",
    dbType: "string",
    title: "TSS",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "identifier",
    htmlType: "text",
    dbType: "string",
    title: "Cédula",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "passport",
    htmlType: "text",
    dbType: "string",
    title: "Passport",
    containerClass: "col-12 col-md-4",
    required: false,
  },
]
