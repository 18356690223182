import moment from "moment"
import React from "react"
const PinComponent = props => {
  const date1 = moment()
  const date2 = moment(props.item?.created)
  const diff = date1.diff(date2, "days")

  const styleProp = {
    color:
      props.item && props.item.assignedTo && props.item.assignedToObj.color
        ? props.item.assignedToObj.color
        : diff < 14
        ? "orangered"
        : "#7a7a7a",
    border: "0",
  }

  const textColor =
    props.item && props.item.assignedTo && props.item.assignedToObj.color
      ? "black !important"
      : diff < 14
      ? "#7ac2ff"
      : "#7a7a7a"
  const iconDisplay =
    props.Type === "vehicle"
      ? "fa fa-map-marker"
      : (props.item && props.item.category.icon) ??
        props.item?.stage?.icon ??
        "fa fa-map-marker"

  const statusStyle = item => {
    let result =
      item && item.placeStatus ? (
        <i
          className={" circle  " + " hasVisit "}
          style={{
            backgroundColor: item.placeStatus.color,
          }}
        ></i>
      ) : null
    return result
  }

  if (props.Type === "vehicle") {
    return (
      <div
        className={"fa-solid fa-circle fa-3x  "}
        title={props.item.text}
        style={{
          color: "orange",
          border: "0",
        }}
        onClick={() => {
          console.log("no working")
          //props.toggle(props.item);
        }}
      >
        {props.item && props.item.assignedTo ? (
          <span className="text-white pinText" style={{ color: textColor }}>
            {props.item.assignedToObj.firstName.substring(0, 1)}
          </span>
        ) : (
          <i
            className={`text-white fa fa-truck`}
            title={props.text}
            style={{
              fontSize: "0.4em",
              position: "absolute",
              left: "0.70em",
              top: "9px",
              color: textColor,
            }}
          />
        )}
        {statusStyle(props.item)}
      </div>
    )
  }
  //REGULAR ICONS

  return (
    <div
      className={
        (props.Type === "vehicle"
          ? "fa-solid fa-truck"
          : "fa-solid fa-location-pin") + " fa-3x "
      }
      title={
        (props.item?.category ? "Categoria: " + props.item.category.name : "") +
        " " +
        (props.item?.stage ? "Etapa: " + props.item?.stage.name : "")
      }
      style={styleProp}
      onClick={() => {
        debugger
        props.toggle(props.id)
      }}
    >
      {props.item && props.item.assignedTo ? (
        <span className="text-white pinText" style={{ color: textColor }}>
          {props.item.assignedToObj.firstName.substring(0, 1)}
        </span>
      ) : (
        <i
          className={`text-white ${iconDisplay}`}
          title={props.text}
          style={{
            fontSize: "0.4em",
            position: "absolute",
            left: "0.50em",
            top: "5px",
            color: textColor,
          }}
        />
      )}
      {statusStyle(props.item)}
    </div>
  )
}

export default PinComponent
